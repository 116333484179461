// Import React Library
import React, { useState, useEffect } from 'react';

// Import Images
import logoPreHouse from '../assets/images/logo-pre-house-2.png'

// Import Firebase 
import { signOut , onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/firebase.config';

// Import Components
import NavbarMobile from './NavbarMobile';
import LogOutConfirmation from './LogOutConfirmation';

// Import Icons
import LogoutIcon from '@mui/icons-material/Logout';

// Import Link react-router
import { Link } from 'react-router-dom';

// Navbar Component
const Navbar = ({status , isDashboardNav}) => {

    // Handle responsive navbar
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean the event when unmount component
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [user, setUser] = useState(null);

    // Verify if the user is loggued
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    const logOutButton = () =>{
        setShowLogOutModal(true);
    }

    // LogOut Firebase Method
    const handleLogout = async () => {
        try {
          await signOut(auth);
        } catch (error) {
          console.error('Error al cerrar sesión:', error);
        }
    };

    // Handle confirmation logout message
    const [showLogOutModal, setShowLogOutModal] = useState(false);

    return (
        <>
            {windowWidth < 1492 ? (
               <NavbarMobile  status={status}/>
            ) : (
                <navbar className={isDashboardNav && 'dashboard-nav'}>
                    <Link to='/'>
                        <img src={logoPreHouse} alt='Logo PreHouse'/>
                    </Link>

                    {
                        isDashboardNav ? 
                        <ul>
                            {user && <p onClick={() => logOutButton()}>Cerrar Sesion  <LogoutIcon className='general-icon'/></p> }
                        </ul> :

                        <ul>
                            <Link to='/windows'>
                                <li>Aberturas</li>
                            </Link>
                            <Link to='/constructions'>
                                <li>Construcciónes</li>
                            </Link>
                            <Link to='/galvanized-iron'>
                                <li>Hierros</li>
                            </Link>
                            <Link to='/isopaneles'>
                                <li>Isopaneles</li>
                            </Link>
                            <Link to='/floor'>
                                <li>Pisos</li>
                            </Link>
                            <Link to='/pre-house-home'>
                                <li>PreHouse Home</li>
                            </Link>
                            <Link to='/coatings'>
                                <li>Revestimientos</li>
                            </Link>

                            { status ? <li className='contact-link'><a href='#contact-form'>Contacto</a></li> : ''}
                        </ul>
                    }

                    {
                        showLogOutModal && (<LogOutConfirmation setShowLogOutModal={setShowLogOutModal} handleLogout={handleLogout}/>)
                    }
                </navbar>
            )}
        </>
    );
};

// Export Navbar Component
export default Navbar;