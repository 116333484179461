// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import StructuresGallery from '../components/StructuresGallery';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';

// Structures Component
const Structures = () => {
    return (
        <>
            <Navbar />
            <Cover section='structures' coverTitle='Estrucuras y Correas' coverSubtitle='Estructuras - Galpones - Correas'/>
            <WhatsappButton />
            <BreadcrumbsComponent currentSection='Estrucuras y Correas'/>
            <div className='structures-grid'>
                <StructuresGallery />
            </div>
            <Footer />
        </>
    );
};

// Export Structures Component
export default Structures;