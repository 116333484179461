// Import React Library
import React, { useState } from "react";

// Import Firebase Aut
import { signInWithEmailAndPassword } from 'firebase/auth';
import{auth } from '../firebase/firebase.config';

// Import Alerts
import Alert from '@mui/material/Alert';

// Import Icons
import { FiMail, FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";

// Import PreHouse Icon
import logoPreHouse from '../assets/images/logo-pre-house-2.png';

const Login = () => {
    // Component States
    const [emailRegister, setEmailRegister] = useState('');
    const [passwordRegister, setPasswordRegister] = useState('');
    const [successMessage , setSuccessMessage] = useState(false);
    const [errorMessage , setErrorMessage] = useState(false);
    const [alertMessage , setAlertMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handle Login
    const handleLogin = async (e) =>{
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, emailRegister, passwordRegister);
            window.location.href = '/crud';
        } catch (error) {
            setErrorMessage(true);
            setAlertMessage('Usuario o contraseña incorrecta');
            console.log(error.message);
            setTimeout(() =>{
                setErrorMessage(false);
                setSuccessMessage(false);
            }, 3000)
        }
    }

  return (
    <div className="min-h-screen bg-gradient-to-br py-6 flex flex-col justify-center sm:py-12" style={{ background: "radial-gradient(circle at center, #ddd 1px, transparent 2px)", backgroundSize: "20px 20px" }}>
      <div className="relative py-3 sm:max-w-xl sm:mx-auto w-2/3 m-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-zinc-700 to-zinc-400 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl login-background"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                { successMessage ? 
                    <Alert variant="filled" severity="success" className='form-alert'>
                        {alertMessage}
                    </Alert> : ''
                }
                { errorMessage ? 
                    <Alert variant="filled" severity="error" className='form-alert'>
                        {alertMessage}
                    </Alert> : ''
                }
                <div className="text-center mb-10">
                  <img 
                    src={logoPreHouse}
                    className="w-40 m-auto"
                    alt="Logo PreHouse"
                  />
                  <h2 className="mt-4 text-3xl font-bold text-gray-900">Bienvenido</h2>
                  <p className="mt-2 text-gray-600">Ingrese sus datos</p>
                </div>

                <form className="space-y-6">
                  <div>
                    <div className="relative">
                      <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      <input
                        type="email"
                        name="email"
                        value={emailRegister}
                        onChange={(e) => setEmailRegister(e.target.value)}
                        className={`block w-full pl-10 pr-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                        placeholder="Dieccion de correo"
                      />
                    </div>
                   
                  </div>
                  <div>
                    <div className="relative">
                      <FiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      <input
                         type={showPassword ? "text" : "password"}
                        name="password"
                        value={passwordRegister}
                        onChange={(e) => setPasswordRegister(e.target.value)}
                        className={`block w-full pl-10 pr-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                        placeholder="Contrasena"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </button>
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={(e)=> handleLogin(e)}
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-zinc-700 hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                  >
                    Sign in
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export Login Component
export default Login;