// Import React Library
import React from 'react';

import { FiX } from "react-icons/fi";

// Aside Component
const Aside = ({isSmallScreen , isSidebarOpen ,setIsSidebarOpen, selectedCategories , setSelectedCategories, categories}) => {
    return (
        <aside
            className={`${isSmallScreen ? "hidden" : "block"} fixed top-0 left-0 w-64 h-full-screen bg-white p-6 shadow-md transform transition-transform duration-300 
            ${
                isSidebarOpen ? "translate-x-0" 
                              : "-translate-x-full"} md:relative md:translate-x-0 overflow-y-auto`
            }
        >
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Categorias</h2>
                <button
                    className="block md:hidden p-2 hover:bg-gray-100 rounded-full"
                    onClick={() => setIsSidebarOpen(false)}
                >
                    <FiX />
                </button>
            </div>
            <div className="space-y-2">
                <div
                    className={`p-2 cursor-pointer rounded-lg ${
                        !selectedCategories ? "bg-gray-600 text-white" : "hover:bg-gray-100"
                    }`}
                    onClick={() => setSelectedCategories("")}
                >
                    Todas las categorias
                </div>
                {categories.map((category) => (
                    <div
                        key={category}
                        className={`p-2 cursor-pointer rounded-lg ${
                            selectedCategories === category
                                ? "bg-gray-600 text-white"
                                : "hover:bg-gray-100"
                        }`}
                        onClick={() => setSelectedCategories(category)}
                    >
                        {category}
                    </div>
                ))}
            </div>
        </aside>
    );
};

// Export Aside Component
export default Aside;