// Import React Library
import React, { useState, useEffect } from "react";

// Import React UUID
import { v4 as uuidv4 } from "uuid";

// Import Components
import Navbar from '../components/Navbar';
import NavbarMobile from "../components/NavbarMobile";
import Aside from "../components/crud/Aside";
import ProductsContent from "../components/crud/ProductsContent";
import DeleteConfirmation from "../components/crud/DeleteConfirmation";
import CreateProductForm from "../components/crud/CreateProductForm";
import EditProductForm from "../components/crud/EditProductForm";
import SkeletonGrid from "../components/Skeleton";
import Footer from '../components/Footer';

// Import Firebse Config and Firestore Instance.
import { db } from "../firebase/firebase.config"; 
import { collection, addDoc, getDocs, deleteDoc, doc } from "firebase/firestore";

// Import Alerts
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const ProductsCrud = () => {

  // Cloudinary Keys.  
  const preset_name = "prehouse";                        
  const cloud_name = "djl7xedoj";

  // Image URL to save in DDBB
  const [ urlImage, setUrlImage ] = useState(null);

  // Aside Status
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Error Upload
  const [errorUpload , setErrorUpload] = useState(false);
  const [errorMessage , setErrorMessage] = useState('');

  // Snackbars Messages
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorEdit, setEditError] = useState(false);
  const [successEdit, setSuccessMessage] = useState(false);

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setError(false);
  };

  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setOpen(false);
  };

  // Product to Edit
  const [productToEdit , setProductToEdit] = useState({});

  // Skeleton Effect
  const [skeletonEffect , setSkeletonEffect] = useState(true);

  // Products
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editForm , setEditForm] = useState(false);
  const [newProduct, setNewProduct] = useState({
    id: "",
    name: "",
    description: "",
    price: "",
    category: "",
    stock: "",
    image: null
  });
  const [previewImage, setPreviewImage] = useState(null);

  // Get Firestore Data.
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "productos")); 
        const productsArray = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().nombre, 
            description: doc.data().descripcion,
            price: doc.data().precio,
            category: doc.data().categoria,
            stock: doc.data().stock,
            image: doc.data().imagenURL, 
        }));

        setProducts(productsArray);
      } catch (error) {
        console.error("Error al obtener productos:", error);
      } finally{
        setSkeletonEffect(false);
      }
    };

    fetchProducts();
  }, [newProduct, productToEdit]);

  // Handle Resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
  
    handleResize();
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Products Category.
  const categories = ["Aberturas", "Hierros", "Isopaneles", "Pisos", "Herramientas", "Revestimientos"];

  // Handle image changes
  const handleImageChange = async(e) => {
    const file = e.target.files[0];

    if (file) {          
        const data = new FormData()            
        data.append('file', e.target.files[0])           
        data.append('upload_preset',preset_name) 

        try {
            const response = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
                method: 'POST',
                body: data
            });

            const file2 = await response.json();     
            setUrlImage(file2.secure_url);            
        } catch (error) {
            console.error('Error uploading image:', error);
        }

        setNewProduct({ ...newProduct, image: file });
        setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Handle Submit Form
  const handleSubmit = async(e) => {
    e.preventDefault();
    const identificador = uuidv4();

    try {
        const docRef = await addDoc(collection(db, "productos"), {
        stock: newProduct.stock,
        nombre: newProduct.name,
        id: identificador,
        precio: parseFloat(newProduct.price),
        descripcion: newProduct.description,
        categoria: newProduct.category,
        imagenURL: urlImage,
    });
        // Show and Hide Success Message
        setOpen(true);
        setShowForm(false);
        setTimeout(() =>{
            setOpen(false);
        }, 3000)
        console.log("Producto agregado con ID: ", docRef.id);
    } catch (e) {
        // Show and Hide Erro Message
        setErrorMessage("Error al agregar el producto: ", e);
        setErrorUpload(true);
        setTimeout(() =>{
            setErrorUpload(false);
            setErrorMessage("");
        }, 3000)
        console.error("Error al agregar el producto: ", e);
    }

    const productData = {
        ...newProduct,
        id: identificador,
        image: previewImage
    };
    
    setProducts([...products, productData]);
    setNewProduct({  id: "", name: "", description: "", price: "", category: "", stock: "", image: null });
    setPreviewImage(null);
  };

  const handleDelete = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };

  const handleEdit = (product) => {
    setProductToEdit(product);
    setEditForm(true);
  };

  const confirmDelete = async () => {
    try {
      // Data to delete Reference
      const productRef = doc(db, "productos", productToDelete.id); 
  
      // First delete Firestore Product
      await deleteDoc(productRef);
  
      // Then delete product in local
      setProducts((prevProducts) => prevProducts.filter((p) => p.id !== productToDelete.id));
  
      // Clean Modal and Close Select
      setShowDeleteModal(false);
      setProductToDelete(null);
      setError(true);
      setTimeout(()=>{
        setError(false);
      }, 3000);

      console.log(`Producto con ID ${productToDelete.id} eliminado exitosamente.`);
    } catch (error) {
      console.error("Error al eliminar producto:", error);
    }
  };
  
  // Filter Products
  const filteredProducts = products.filter((product) => {
    const matchesSearch = product.name?.toLowerCase().includes(searchTerm.toLowerCase()) || false;
    const matchesCategory = !selectedCategories || selectedCategories === product.category;
    return matchesSearch && matchesCategory;
  });

  return (
    <>
        {!isSmallScreen ? <Navbar isDashboardNav={true}/> : <NavbarMobile isDashboardNav={true}/>}

        <div className="flex">
          <Aside 
              isSmallScreen={isSmallScreen}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              categories={categories}
          />

          <div className="min-h-screen bg-gray-100 w-full">
            {
              skeletonEffect ? <SkeletonGrid /> :

              <ProductsContent 
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setShowForm={setShowForm}
                filteredProducts={filteredProducts}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                setProductToEdit={setProductToEdit}
              />
            }

            {showForm && (
                <CreateProductForm 
                    setShowForm={setShowForm}
                    errorUpload={errorUpload}
                    handleSubmit={handleSubmit}
                    newProduct={newProduct}
                    setNewProduct={setNewProduct}
                    errorMessage={errorMessage}
                    categories={categories}
                    handleImageChange={handleImageChange}
                    previewImage={previewImage}
                />
            )}

            {editForm && (
              <EditProductForm 
                setEditForm={setEditForm}
                categories={categories}
                productToEdit={productToEdit}
                setProductToEdit={setProductToEdit}
                previewImage={previewImage}
                setSuccessMessage={setSuccessMessage}
                setEditError={setEditError}
              />
            )}

            {showDeleteModal && (
                <DeleteConfirmation 
                    productToDelete={productToDelete.name} 
                    setShowDeleteModal = {setShowDeleteModal}
                    confirmDelete = {confirmDelete}
                />
            )}
          </div>
        </div>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
            >
                Se ha creado un producto.
            </Alert>
        </Snackbar>

        <Snackbar open={error} autoHideDuration={6000} onClose={handleErrorClose}>
            <Alert
                onClose={handleErrorClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                Se ha eliminado un producto.
            </Alert>
        </Snackbar>

        <Snackbar open={successEdit} autoHideDuration={6000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
            >
                Se ha editado un producto.
            </Alert>
        </Snackbar>

        <Snackbar open={errorEdit} autoHideDuration={6000} onClose={handleErrorClose}>
            <Alert
                onClose={handleErrorClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                No se ha podido editar el producto.
            </Alert>
        </Snackbar>
        <Footer />
    </>
  );
};

// Export Producs Crud Component
export default ProductsCrud;