// Import Rect Library
import React, { useState , useEffect} from "react";

// Import Firebse Config and Firestore Instance.
import { db } from "../../firebase/firebase.config"; 
import { collection, getDocs, query, where } from "firebase/firestore";

// Import React Icons
import { FiShoppingCart } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

// Import Components
import Skeleton from "../ProductSkeleton";

// Import labels
import Chip from '@mui/material/Chip';

// Product Grid Component
const ProductGrid = ({categoria}) => {

  // Prodcuts State
  const [dbProducts , setDbProducts] = useState([]);
  const [skeleton , setSkeleton] = useState(true);

   // Paginación
   const [currentPage, setCurrentPage] = useState(1);
   const productsPerPage = 6; 

  // Get Firestore Data.
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, "productos"), where("categoria", "==", categoria))
          );
     
          const productsArray = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              name: doc.data().nombre, 
              description: doc.data().descripcion,
              price: doc.data().precio,
              category: doc.data().categoria,
              stock: doc.data().stock,
              image: doc.data().imagenURL, 
          }));
  
          setDbProducts(productsArray);
        } catch (error) {
          console.error("Error al obtener productos:", error);
        } finally{
          setSkeleton(false)
        }
      };
  
      fetchProducts();
    }, []);

  // Calculate products count
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = dbProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(dbProducts.length / productsPerPage);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const ProductCard = ({ product }) => {
    const truncatedDescription = product.description.length > 80
      ? `${product.description.substring(0, 80)}...`
      : product.description;

    return (
      <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 
        w-full sm:w-[70%] md:w-[350px] mx-auto p-2"
      >

        <Chip 
          label={product.stock <= 0 ? "Agotado" : "Disponible" } 
          color={product.stock <= 0 ? "error" : 'success' }
          sx={{ position: 'relative', padding: 2, left: 8}}
        />
          
        <img
          src={product.image}
          alt={product.name} 
          className="w-full h-60 sm:h-40 md:h-60 object-cover"
          onError={(e) => {
            e.target.src = "https://images.unsplash.com/photo-1560393464-5c69a73c5770";
          }}
        />

        <div className="p-4 sm:p-3 md:p-4">
          <h3 className="text-xl sm:text-lg md:text-xl font-semibold mb-2">{product.name}</h3>
          <p className="text-gray-600 mb-2 text-sm sm:text-xs md:text-sm">{truncatedDescription}</p>

          {product.description.length > 80 && (
            <p className="text-yellow-600 text-sm sm:text-xs md:text-sm mb-2">Descripcion excede los 80 caracteres</p>
          )}

          <div className="flex justify-between items-center mb-2">
            <span className="text-lg sm:text-sm md:text-lg font-bold text-gray-600">USD {product.price}</span>
            <span
              className={`px-2 py-1 rounded-full text-sm sm:text-xs md:text-sm ${
                product.stock > 0 ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
              }`}
            >
              {product.stock > 0 ? "En stock" : "Fuera de stock"}
            </span>
          </div>

          <p className="text-gray-500 mb-4 text-sm sm:text-xs md:text-sm">Stock: {product.stock}</p>

          <p className="text-gray-500 mb-4 text-sm sm:text-xs md:text-sm">Categoria: {product.category}</p>

          <button
            onClick={() => setSelectedProduct(product)}
            className="w-full bg-gray-600 text-white py-2 sm:py-1 md:py-2 rounded-md hover:bg-gray-700 transition-colors duration-300 flex items-center justify-center gap-2 text-sm sm:text-xs md:text-sm"
          >
            <FiShoppingCart />
            Ver Producto
          </button>
        </div>
      </div>
    );
  };

  const ProductDetail = ({ product, onClose }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-64 z-50">
        <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-auto scrollbar-hidden">
          <div className="p-6">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-2xl font-bold text-gray-700">{product.name}</h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
              >
                <AiOutlineClose size={24} />
              </button>
            </div>
            <img
              src={product.image}
              alt={product.name}
              className="w-4/5 rounded-lg mb-4 mx-auto"
              onError={(e) => {
                e.target.src = "https://images.unsplash.com/photo-1560393464-5c69a73c5770";
              }}
            />
            <div className="space-y-4">
              <p className="text-gray-700">{product.description}</p>
              <div className="flex justify-between items-center">
                <span className="text-2xl font-bold text-gray-700">
                  USD {product.price}
                </span>
                <span className={`px-3 py-1 rounded-full ${product.stock > 0 ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}`}>
                  {product.stock > 0 ? "En stock" : "Fuera de stock"}
                </span>
              </div>
              <p className="text-gray-600">Stock: {product.stock}</p>
              <p className="text-gray-600">Categoria: {product.category}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-20">
      {!skeleton ? 
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {currentProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div> : 
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      }

      <div className="flex justify-center mt-6 gap-2">
        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number + 1}
            className={`px-4 py-2 rounded-md ${
              currentPage === number + 1
                ? "bg-gray-700 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handlePageChange(number + 1)}
          >
            {number + 1}
          </button>
        ))}
      </div>

      {selectedProduct && (
        <ProductDetail
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </div>
  );
};

// Export Product Grid
export default ProductGrid;