// Import React Library
import React from 'react';

// Import React Icons
import { FiSearch, FiTrash2, FiEdit2 } from "react-icons/fi";

// ProductsContent Component
const ProductsContent = ({searchTerm, setSearchTerm, setShowForm, filteredProducts, handleDelete, handleEdit}) => {
    return (
        <div className="flex-1 p-8">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-8">
                    <div className="relative w-full md:w-64">
                        <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Buscar productos..."
                            className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    <button
                        onClick={() => setShowForm(true)}
                        className="bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-500 transition-colors md:ml-4"
                    >
                        Crear Producto
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredProducts.map((product) => (
                    <div
                        key={product.id}
                        className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                    >
                        <img
                            src={product.image}
                            alt={product.name}
                            className="w-full h-48 object-cover"
                            onError={(e) => {
                                e.target.src = "https://images.unsplash.com/photo-1560393464-5c69a73c5770";
                            }}
                        />
                        <div className="p-4">
                            <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
                            <p className="text-gray-600 mb-2">{product.description}</p>
                            <p className="text-gray-600 font-bold">USD {product.price}</p>
                            <p className="text-gray-600 mt-2">Stock: {product.stock} unidades</p>
                            <div className="flex justify-end mt-4 space-x-2">
                                <button
                                    onClick={() => handleDelete(product)}
                                    className="p-2 text-red-600 hover:bg-red-100 rounded-full"
                                    aria-label="Delete product"
                                >
                                    <FiTrash2 />
                                </button>

                                <button
                                    onClick={() => handleEdit(product)}
                                    className="p-2 text-blue-600 hover:bg-blue-100 rounded-full"
                                    aria-label="Edit product"
                                >
                                    <FiEdit2 />
                                </button>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

// Export ProductsContent Component
export default ProductsContent;
