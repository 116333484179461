import React from "react";

const ProductSkeleton = () => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 animate-pulse">
    {/* Skeleton de la Imagen */}
    <div className="w-full h-48 bg-gray-300"></div>
    
    {/* Skeleton del Contenido */}
    <div className="p-4 space-y-3">
      {/* Título */}
      <div className="h-6 bg-gray-300 rounded w-3/4"></div>
      {/* Descripción */}
      <div className="h-4 bg-gray-300 rounded w-full"></div>
      <div className="h-4 bg-gray-300 rounded w-5/6"></div>
      
      {/* Mensaje de advertencia */}
      <div className="h-4 bg-yellow-300 rounded w-1/2"></div>
      
      {/* Precio y Stock */}
      <div className="flex justify-between items-center mt-2">
        <div className="h-6 bg-gray-300 rounded w-1/4"></div>
        <div className="h-6 bg-gray-300 rounded w-1/4"></div>
      </div>
      
      {/* Categoría */}
      <div className="h-4 bg-gray-300 rounded w-1/3"></div>
      
      {/* Botón */}
      <div className="h-10 bg-gray-400 rounded-md mt-4"></div>
    </div>
  </div>
);

export default ProductSkeleton;
