// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import ProductGrid from '../components/products/ProductsGrid';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';

// Floor Component Open
const Floor = () => {
    return (
        <>
            <Navbar />
            <Cover section='floor' coverTitle='Pisos' coverSubtitle='Pisos - Camineros - Niveladores - Zocalos - Baldosas'/>
            <WhatsappButton />
            <BreadcrumbsComponent currentSection='Pisos'/>
            <ProductGrid categoria={'Pisos'} />
            <Footer />
        </>
    );
};

// Export Floor Component
export default Floor;