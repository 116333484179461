import React, { useState } from "react";

// Import Images
import logoPreHouse from "../assets/images/logo-pre-house-2.png";

// Import Icons
import { FiAlignJustify } from "react-icons/fi";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import InstagramIcon from '@mui/icons-material/Instagram';

// Import Firebase
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase.config";

// Import Link react-router
import { Link } from "react-router-dom";

const NavbarMobile = ({ status, isDashboardNav }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = "/login";
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <>
      {/* Navbar principal */}
      <nav className="nav-mobile flex items-center justify-between p-4 bg-white shadow-md">
        <Link to="/">
          <img src={logoPreHouse} alt="Logo PreHouse" className="w-32 mx-5" />
        </Link>

        {!isDashboardNav ? (
          <FiAlignJustify
            className="menu-icon cursor-pointer text-gray-600"
            onClick={() => setOpenMobileMenu(true)}
          />
        ) : (
          <LogoutIcon
            onClick={handleLogout}
            className="text-gray-600 hover:text-gray-500 cursor-pointer"
            fontSize="large"
          />
        )}
      </nav>

        {/* Overlay sombreado */}
        {openMobileMenu && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 z-40"
          onClick={() => setOpenMobileMenu(false)}
        ></div>
      )}

      {/* Menú deslizante */}
      <ul
        className={`fixed top-0 right-0 h-full w-[80%] bg-white shadow-lg transform ${
          openMobileMenu ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-[1000ms] ease-in-out z-50`}
      >
        <CloseIcon
          className="close-icon absolute top-4 right-4 text-gray-600 cursor-pointer"
          onClick={() => setOpenMobileMenu(false)}
        />
        <div className="mt-12 space-y-6 px-6 py-12">
          <Link to="/windows">
            <li className="text-neutral-800 hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">Aberturas</li>
          </Link>
          <Link to="/constructions">
            <li className="text-neutral-800 hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">Construcciones</li>
          </Link>
          <Link to="/galvanized-iron">
            <li className="text-neutral-800 hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">Hierros</li>
          </Link>
          <Link to="/isopaneles">
            <li className="text-neutral-800 hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">Isopaneles</li>
          </Link>
          <Link to="/floor">
            <li className="text-neutral-800 hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">Pisos</li>
          </Link>
          <Link to="/pre-house-home">
            <li className="text-neutral-800 hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">PreHouse Home</li>
          </Link>
          <Link to="/coatings">
            <li className="text-neutral-800 hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">Revestimientos</li>
          </Link>
          {status && (
            <>
                <li className="text-neutral-800 font-bold hover:text-gray-200 cursor-pointer h-[6vh] transition-all duration-1000">
                <a className="text-neutral-800 font-bold hover:text-gray-200 h-[6vh] transition-all duration-1000" href="#contact-form">Contacto</a>
                </li>
            </>
          )}
         <div class="border-t border-gray-300 my-12"></div>
         <br />

         <a href='https://www.instagram.com/prehouseuruguay' target="_blank" rel="noopener noreferrer" className="cursor-pointer text-neutral-800 hover:text-gray-200 transition-all duration-1000">
            <InstagramIcon fontSize='large' className='footer-icon cursor-pointer hover:text-gray-200 transition-all duration-1000'/>            
         </a>
        </div>
      </ul>
    </>
  );
};

export default NavbarMobile;
