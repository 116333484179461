// Import React Library
import React, {useState} from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import ProductGrid from '../components/products/ProductsGrid';
import WhatsappButton from '../components/WhatsappButton';

// Isopaneles Component
const Isopaneles = () => {
    return (
        <>
            <Navbar />
            <Cover section='paneles' coverTitle='Isopaneles' coverSubtitle='Trapezoidal - Engrafado - Poliestireno - Poliuretano - Foil'/>
            <WhatsappButton />
            <BreadcrumbsComponent currentSection='Isopaneles'/>
            <ProductGrid categoria={'Isopaneles'} />
            <Footer />
        </>
    );
};

// Export Isopaneles Component
export default Isopaneles;