// Import React Library
import React from 'react';

// Import Grid Component
import Grid from '@mui/material/Grid';

// Import Card Component
import Card from '@mui/material/Card';

// Import Card Content
import CardContent from '@mui/material/CardContent';

// Import Box
import { Box } from '@mui/material';

// Import Skeleton Grid
import Skeleton from '@mui/material/Skeleton';

// Skeleton Grid Component
function SkeletonGrid() {
  return (
    <div className="flex-1 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-8">
          <div className="relative w-full md:w-64">
            <Skeleton variant="rectangular" height={40} />
          </div>
          <Skeleton variant="rectangular" width={150} height={40} />
        </div>

        <Grid container spacing={6}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 1 }}>
                <Skeleton variant="rectangular" width="100%" height={192} />

                <CardContent>
                  <Skeleton width="60%" height={24} />
                  <Skeleton width="80%" height={20} style={{ marginTop: 8 }} />
                  <Skeleton width="40%" height={20} style={{ marginTop: 8 }} />
                  <Skeleton width="30%" height={20} style={{ marginTop: 8 }} />
                  
                  <Box display="flex" justifyContent="flex-end" gap={1} marginTop={2}>
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="circular" width={32} height={32} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

// Export Skeleton Grid
export default SkeletonGrid;
