// Import React Library
import React,  { useRef, useState }  from 'react';

// Import TextFields
import TextField from '@mui/material/TextField';

// Import Email.js Library
import emailjs from '@emailjs/browser';

// Import Alerts
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

// Import Components
import PanelesType from '../components/PanelesTypes.js';
import HandMade from './HandMade.js';

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// Import React Reveal
import { Fade } from "react-awesome-reveal";

// Contact Component
const Contact = () => {

    // Use form reference
    const form = useRef();

    // Input values
    const [userName , setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [formMessage, setFormMessage] = useState('');
    const [userSize , setUserSize] = useState('');
    const [userPhone , setUserPhone] = useState('');
    const [userSubject , setUserSubject] = useState('');

    const [successMessage , setSuccessMessage] = useState(false);
    const [errorMessage , setErrorMessage] = useState(false);
    const [alertMessage , setAlertMessage] = useState('');

    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [selectedKey, setSelectedKey] = useState(0);

    const handleClick = () => {
        setOpen(true);
    };

    const handleError = () => {
        setError(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    // Send email function
    const sendContactEmail = (e) =>{
        // Prevent default action
        e.preventDefault();

        if(userName === '' || userEmail === '' || formMessage === '' ||  userPhone === ''){
            setAlertMessage('Debe completar todos los campos del formulario');
            setErrorMessage(true);
            setSuccessMessage(false);
            handleError();

            setTimeout(() =>{
                setErrorMessage(false);
                setSuccessMessage(false);
            }, 3000)
        }else{
            emailjs
            .sendForm('service_7578jaz', 'template_a63j8by', form.current, {
                publicKey: 'pxLmwp6Ex0LEGM1zq'
            })
            .then(
                () => {
                    // Show success alert
                    setAlertMessage('¡Muchas gracias, su mensaje ha sido enviado con éxito!');
                    handleClick();
                    setErrorMessage(false);
                    setSuccessMessage(true);

                    // Clean form
                    setUserEmail('');
                    setUserName('');
                    setFormMessage('');
                    setUserSubject('');
                    setUserPhone('');
                    setUserSize('');

                    setSelectedKey(prevKey => prevKey + 1); 

                    // Hide Success Alert
                    setTimeout(() =>{
                        setErrorMessage(false);
                        setSuccessMessage(false);
                    }, 3000)
                },
                (error) => {
                    // Show error message
                    setAlertMessage(error.text)
                    setErrorMessage(true);
                    setSuccessMessage(false);
                },
            );
        }
    }

    return (
        <div id="contact-form" className='contact-form'>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    ¡Muchas gracias, su mensaje ha sido enviado con éxito!
                </Alert>
            </Snackbar>

            <Snackbar open={error} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert
                    onClose={handleErrorClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Debe completar todos los campos del formulario.
                </Alert>
            </Snackbar>

            <h2>SOLICITAR COTIZACIÓN <FontAwesomeIcon icon={faEnvelope} className='contact-icon'/></h2>
            <span className='span-title'></span>

            <div className='contact-container'>
                <form ref={form} onSubmit={sendContactEmail} className='contact-form'>
                    { successMessage ? 
                        <Alert variant="outlined" severity="success" className='form-alert'>
                            {alertMessage}.
                        </Alert> : ''
                    }

                    { errorMessage ? 
                        <Alert variant="outlined" severity="error" className='form-alert'>
                            {alertMessage}.
                        </Alert> : ''
                    }

                    <div className='form-container'>
                        <TextField
                            className='input-text'
                            id="standard-multiline-flexible user-name"
                            label="Nombre"
                            multiline
                            maxRows={4}
                            variant="standard"
                            name="user_name"
                            onChange={(e) => setUserName(e.target.value)}
                            value={userName}
                        />
                    </div>

                    <div className='form-container'>
                        <TextField
                            className='input-text'
                            id="standard-multiline-flexible user-email"
                            label="Email"
                            multiline
                            maxRows={4}
                            variant="standard"
                            name="user_email"
                            onChange={(e) => setUserEmail(e.target.value)}
                            value={userEmail}
                        />
                    </div>

                    <div className='form-container'>
                        <TextField
                            className='input-text'
                            id="standard-multiline-flexible user-name"
                            label="Telefono"
                            multiline
                            maxRows={4}
                            variant="standard"
                            name="user_phone"
                            onChange={(e) => setUserPhone(e.target.value)}
                            value={userPhone}
                        />
                    </div>

                    <div className='form-container'>
                        <TextField
                            className='input-text text-area'
                            id="standard-multiline-flexible form-message"
                            label="Mensaje"
                            multiline
                            maxRows={10}
                            variant="standard"
                            name="message"
                            onChange={(e) => setFormMessage(e.target.value)}
                            value={formMessage}
                        />
                    </div>

                    <Fade direction='right' triggerOnce={true}>
                        <button className="contact-button">
                            <div class="translate-x-0 opacity-100 transition group-hover:-translate-x-[150%] group-hover:opacity-0 uppercase">
                                ENVIAR
                            </div>
                        </button>
                    </Fade>
                </form>
            </div>
        </div>
    );
};

// Export Contact Component
export default Contact;