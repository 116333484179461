// Import React Library
import * as React from 'react';

// Import Breadcrumbs component
import Breadcrumbs from '@mui/material/Breadcrumbs';

// Import Typography
import Typography from '@mui/material/Typography';

// Import Material UI Components
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';

export default function BreadcrumbsComponent({currentSection}) {
  const breadcrumbs = [
    <Link underline="hover" 
          key="1" color="inherit" 
          to='/' 
          style={{ textDecoration: 'none', color: '#B0BEC5' }}
    >
      Inicio
    </Link>,
    <Typography key="2" sx={{ color: 'text.primary' }}>
      {currentSection}
    </Typography>,
  ];

  return (
    <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 5, marginLeft: '10%'}}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
