// Import React Library
import React, {useState} from 'react';

// Import Material UI Accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Import Icons
import QuizIcon from '@mui/icons-material/Quiz';

// Import Images
import roofImage from '../assets/images/roof-example.svg';

// Faq Component
const Faq = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='faq-section'>
            <h2>Faq <QuizIcon className='faq-icon'/></h2>
            <span></span>

            <div className='faq-container'>
                <Accordion className='acordion-container'  sx={{ boxShadow: 'none', border: 'none' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h3>Entregas</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Contamos con entrega inmediata, sujeta a stock.</p>
                        <br />
                        <p>Los pedidos son ingresados a nuestro sistema y, en base a la demanda, se van preparando para entregar.</p>
                        <br />
                        <p>
                            En caso de que los paneles estén en stock, podemos entregar de 24 a 72 horas. 
                        </p>
                        <br />
                        <p>
                            En el caso de que haya que producir los paneles, la demora de entrega es de 10 a 12 días hábiles desde que se encarga.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='acordion-container'  sx={{ boxShadow: 'none', border: 'none' }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <h3>Envíos</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                       <p>La empresa no cuenta con servicio de envío.</p>
                       <br />
                       <p>
                            Una vez se confirme que el pedido está pronto para cargar, el sector de expedición notificará al vendedor, 
                            y desde ventas se comunicarán con el cliente para notificarle que ya puede retirar su mercadería
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='acordion-container'  sx={{ boxShadow: 'none', border: 'none' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <h3>Formas de pago</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Contamos con todas las formas de pago: 
                        </p>
                        <br />
                        <p> 
                            Al contado a través de transferencia, contado en nuestro local, y con todas las tarjetas de crédito y débito. 
                        </p>
                        <br />
                        <p>                            
                            Consulte por la opción de pago a través de MercadoPago. Importante: por seguridad no trabajamos con efectivo.
                        </p>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

// Expor Faq Component
export default Faq;