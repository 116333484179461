// Import React Library
import React, { useState } from 'react';

// Import React Icons
import { FiX } from "react-icons/fi";

// Import Alerts
import Alert from '@mui/material/Alert';

// Import Firebase
import { db } from '../../firebase/firebase.config'; 
import { doc, updateDoc } from 'firebase/firestore';

// CreateProductForm Component
const CreateProductForm = ({setEditForm, successUpload, errorUpload, categories, productToEdit, setEditError, setSuccessMessage, setProductToEdit}) => {
    
    // Product to Edit
    const [editableProduct , setEditableProduct] = useState(productToEdit);

    // Handle Edit Form
    const handleSubmit = async (e) =>{
        e.preventDefault();

        try {
            const productoRef = doc(db, 'productos', editableProduct.id);
            await updateDoc(productoRef, {
                stock: editableProduct.stock,
                nombre: editableProduct.name,
                precio: editableProduct.price,
                descripcion: editableProduct.description,
                categoria: editableProduct.category,
                imagenURL: editableProduct.image,
            });

            // Show and Hide Success Message
            setSuccessMessage(true);
            setEditForm(false);
            setTimeout(() =>{
                setSuccessMessage(false);
            }, 3000)
            console.log("Producto editado con ID: ", editableProduct.id);
            setProductToEdit({});
          } catch (e) {
            // Show and Hide Erro Message
            setEditError(true);
            setTimeout(() =>{
                setEditError(false);
            }, 3000)
            console.error('Error al actualizar el producto:', e);
          }
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-screen overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Editar producto</h2>
                    <button
                        onClick={() => setEditForm(false)}
                        className="p-2 hover:bg-gray-100 rounded-full"
                    >
                        <FiX />
                    </button>
                </div>

                {
                    successUpload && <Alert variant="outlined" severity="success" className="p-2 my-2"> El producto se ha editado correctamente.</Alert>
                }

                {
                    errorUpload && <Alert variant="outlined" severity="error" className="p-2 my-2">El producto no se ha podido editar.</Alert>
                }
                
                <form onSubmit={handleSubmit} className='overflow-y-auto'>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Nombre</label>
                            <input
                                type="text"
                                required
                                className="w-full p-2 border rounded-lg"
                                value={editableProduct.name}
                                onChange={(e) => setEditableProduct({ ...editableProduct, name: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Descripcion</label>
                            <textarea
                                required
                                className="w-full p-2 border rounded-lg"
                                value={editableProduct.description}
                                onChange={(e) => setEditableProduct({ ...editableProduct, description: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Precio</label>
                            <input
                                type="number"
                                required
                                step="0.01"
                                className="w-full p-2 border rounded-lg"
                                value={editableProduct.price}
                                onChange={(e) => setEditableProduct({ ...editableProduct, price: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Stock</label>
                            <input
                                type="number"
                                required
                                min="0"
                                className="w-full p-2 border rounded-lg"
                                value={editableProduct.stock}
                                onChange={(e) => setEditableProduct({ ...editableProduct, stock: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Categoria</label>
                            <select
                                required
                                className="w-full p-2 border rounded-lg"
                                value=''
                                onChange={(e) => setEditableProduct({ ...editableProduct, category: e.target.value })}
                            >
                                <option value={editableProduct.category}>{editableProduct.category}</option>
                                {categories.map((category) => (
                                    category !== editableProduct.category ? <option value={category} key={category}>{category}</option> : null
                                )).filter(option => option !== null)}
                            </select>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-gray-600 text-white py-2 rounded-lg hover:bg-gray-500 transition-colors"
                        >
                            Editar Producto
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

// Exprt CreateProductForm Component
export default CreateProductForm;