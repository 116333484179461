// Import React Library
import React from 'react';

// Import React Virtual DOM.
import ReactDOM from 'react-dom/client';

// Import Components
import App from './App';
import Error from './pages/Error';
import Login from './pages/Login';
import GalvanizedIron from './pages/GalvanizedIron';
import BlackIron from './pages/BlackIron';
import HotIron from './pages/HotIron';
import Isopaneles from './pages/Isopaneles';
import Constructions from './pages/Constructions';
import Windows from './pages/Windows';
import PreHouseHome from './pages/PreHouseHome';
import Coatings from './pages/Coatings';
import Floor from './pages/Floor';
import Structures from './pages/Structures';
import MakeYourOwnModule from './pages/MakeYourOwnModule';
import ProductsCrud from './pages/ProductsCrud';

import { AuthProvider } from "./components/context/AuthContext";

// Import Private Route
import PrivateRoute from './components/context/PrivateRoute';

// Import Routing System
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Router Component
const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement : <Error />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/galvanized-iron',
    element: <GalvanizedIron />
  },
  {
    path: '/black-iron',
    element: <BlackIron />
  },
  {
    path: '/hot-iron',
    element: <HotIron />
  },
  {
    path: '/isopaneles',
    element: <Isopaneles />
  },
  {
    path: '/constructions',
    element: <Constructions />
  },
  {
    path: '/windows',
    element: <Windows />
  },
  {
    path: '/pre-house-home',
    element: <PreHouseHome />
  },
  {
    path: '/coatings',
    element: <Coatings />
  },
  {
    path: '/floor',
    element: <Floor />
  },
  {
    path: '/structures',
    element: <Structures />
  },
  {
    path: '/modules',
    element: <MakeYourOwnModule />
  },
  {
    path: '/crud',
    element: 
    <PrivateRoute>
      <ProductsCrud />
    </PrivateRoute>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <RouterProvider router={router}/>
  </AuthProvider>
);