// Import React Library
import React from 'react';

// Import Component
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import ProductGrid from '../components/products/ProductsGrid';
import WhatsappButton from '../components/WhatsappButton';
import Footer from '../components/Footer';

// PreHoue Home Component
const PreHouseHome = () => {
    return (
        <>
            <Navbar />
            <Cover section='home-products' coverTitle='PreHouse Home' coverSubtitle='Herramientas - Losa radiante - Selladores - Iluminaria'/>
            <BreadcrumbsComponent currentSection='PreHouse Home'/>
            <ProductGrid categoria={'Herramientas'} />
            <WhatsappButton />
            <Footer />
        </>
    );
};

// Export PreHouse Home Component
export default PreHouseHome;