// Import React Library
import React from 'react';

// LogOutConfirmation Component
const LogOutConfirmation = ({setShowLogOutModal, handleLogout}) => {

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-sm">
                <h2 className="text-xl font-bold mb-4">Confirmar cierre de sesion</h2>
                <p className="mb-4">Estas seguro que desea cerrar sesion?</p>
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={() => setShowLogOutModal(false)}
                        className="px-4 py-2 border rounded-lg hover:bg-gray-100"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={() => handleLogout()}
                        className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                    >
                        Cerrar Sesion
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LogOutConfirmation;