// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import ProductGrid from '../components/products/ProductsGrid';
import WhatsappButton from '../components/WhatsappButton';
import Footer from '../components/Footer';

// Windows Component
const Windows = () => {
    return (
        <>
            <Navbar />
            <Cover section='windows' coverTitle='Aberturas' coverSubtitle='Ventanas - Puertas Ventana - Puertas de Madera'/>
            <WhatsappButton />
            <BreadcrumbsComponent currentSection='Aberturas'/>
            <ProductGrid categoria={'Aberturas'} />
            <Footer />
        </>
    );
};

// Export Windows Component
export default Windows;