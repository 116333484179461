// Import React Library.
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import BreadcrumbsComponent from '../components/BreadcrumbsComponent';
import ProductGrid from '../components/products/ProductsGrid';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';

// Galvanized Iron Component.
const GalvanizedIron = () => {
    return (
        <>
            <Navbar />
            <Cover section='steel' coverTitle='Caños de hierro' coverSubtitle='Galvanizados en frio - Galvanizados en caliente - Comunes'/>
            <WhatsappButton />
            <BreadcrumbsComponent currentSection='Hierros'/>
            <ProductGrid categoria={'Hierros'} />
            <Footer />
        </>
    );
};

// Export Galvanized Iron Component.
export default GalvanizedIron;